<template>
	<div class="buttons-wrapper">
		<base-button
			v-for="(button, i) in this.value['buttons-group']"
			:type="button.btn_type"
			:key="i"
			:to="button.link"
		>
			<cms-text :props="{ clear: true}" :value="`${prefix}.buttons-group.${i}.btn_content`"/>
		</base-button>
	</div>
</template>

<script>
import BaseButton from '../../components/atoms/BaseButton'
export default {
	components: {
		BaseButton
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.row-reverse {
	.buttons-wrapper {
		margin-bottom: 3rem;
	}
}
.buttons-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
	margin-bottom: 0;
	& a {
		margin-top: 0;
		margin-bottom: 2rem;
		&:only-child {
			margin-bottom: 0;
		}
	}
	@include media-breakpoint-up(md) {
		& a:first-child {
			margin-right: 2rem;
		}
		& a:not(:first-child) {
			margin-bottom: 2rem;
		}
		flex-direction: row;
	}
	@include media-breakpoint-up(lg) {
		margin-top: 6rem;
		& a:first-child {
			margin-right: 2rem;
		}
	}
	@include media-breakpoint-up(xl) {
		& a:first-child {
			margin-right: 1.5rem;
		}
	}
	@include media-breakpoint-up(xxl) {
		& a:first-child {
			margin-right: 2rem;
		}
	}
}
</style>
